<template>
  <div
    class="BookLayout">
    <div
      class="__leading">
      <div
        class="container">
        <IconBase
          class="__logo"
          name="domo"
          width="80"
          height="80"
        />

        <ListIcons
          v-if="computedFeatures"
          :items="computedFeatures"
          key="home-features">
        </ListIcons>

        <LogoBanner
          class="__LogoBanner"
          :breakpoints="[
            { width: 0, columns: 3, rows: 1 },
            { width: 550, columns: 4, rows: 1 },
          ]"
          :logos="[
            {
              name: 'Zillow',
              maxWidth: 120,
              src: 'https://web-assets.domo.com/miyagi/images/customer/zillow/logo/logo-customer-zillow-white.svg',
            },
            {
              name: 'Univision',
              maxWidth: 60,
              src: 'https://web-assets.domo.com/miyagi/images/customer/univision/logo/logo-customer-univision-white.svg',
            },
            {
              name: 'Ebay',
              maxWidth: 90,
              src: 'https://web-assets.domo.com/miyagi/images/customer/ebay/logo/logo-customer-ebay-white.svg',
            },
            {
              name: 'DHL',
              src: 'https://web-assets.domo.com/miyagi/images/customer/dhl/logo/logo-customer-dhl-white.svg',
            },
          ]"
        />
      </div>

      <div
        class="__slant">
      </div>
    </div>

    <div
      class="__trailing">
      <slot/>
    </div>
  </div>
</template>


<script>
import IconBase from '@/components/IconBase.vue';
import ListIcons from '@/components/ListIcons.vue';
import LogoBanner from '@/components/LogoBanner.vue';

export default {
  name: 'BookLayout',

  components: {
    IconBase,
    ListIcons,
    LogoBanner,
  },

  computed: {
    computedFeatures() {
      return this.$i18n.messages[this.$i18n.locale].home.features;
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.BookLayout {
  display: flex;
  min-height: 100vh;
}

.__leading {
  position: relative;
  flex: 0 0 40%;
  color: $white;
  background: $light-blue;
}
.__trailing {
  flex: 0 0 60%;
}

.__slant {
  position: absolute;
  top: -5%;
  right: -25px;
  width: 50%;
  height: 110%;
  background: $light-blue;
  transform: rotate(4deg);
}
</style>
